<template>
  <header class="header">
    <div class="container">
      <div style="display: flex;align-items: center;">
        <h1 class="logo">
          <img src="@/assets/logo.jpg" alt="" class="logo-img" />
          <span>隐数智能科技</span>
        </h1>
        <nav style="color: white;font-size: 18px;margin-left: 16px;">
          <ul>
            <li><a href="#">首页</a></li>
            <li><a href="#">产品</a></li>
            <li><a href="#">客户案例</a></li>
            <li><a href="#">服务与支持</a></li>
            <li><a href="#">关于我们</a></li>
          </ul>
        </nav>
      </div>
      <!-- <div class="contact-info">
        <span>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
            <path
              fill="currentColor"
              d="M79.36 432.256 591.744 944.64a32 32 0 0 0 35.2 6.784l253.44-108.544a32 32 0 0 0 9.984-52.032l-153.856-153.92a32 32 0 0 0-36.928-6.016l-69.888 34.944L358.08 394.24l35.008-69.888a32 32 0 0 0-5.952-36.928L233.152 133.568a32 32 0 0 0-52.032 10.048L72.512 397.056a32 32 0 0 0 6.784 35.2zm60.48-29.952 81.536-190.08L325.568 316.48l-24.64 49.216-20.608 41.216 32.576 32.64 271.552 271.552 32.64 32.64 41.216-20.672 49.28-24.576 104.192 104.128-190.08 81.472L139.84 402.304zM512 320v-64a256 256 0 0 1 256 256h-64a192 192 0 0 0-192-192m0-192V64a448 448 0 0 1 448 448h-64a384 384 0 0 0-384-384"
            ></path>
          </svg>
          021-66886688
        </span>
        <button>立即咨询</button>
      </div> -->
    </div>
  </header>
</template>

<style scoped lang="scss">
.header {
  // background: #b1b1b121; /* Light background for a clean look */
  padding: 15px 0;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif; /* Modern, clean font */
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
  margin: 0 auto;
  padding: 0 20px;
}

.logo {
  font-size: 24px;
  font-weight: 500;
  color: white; /* Dark gray for a techy feel */
  display: flex;
  align-items: center;

  .logo-img {
    width: 50px;
    margin-right: 12px;
  }
}

nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

nav ul li {
  margin: 0 20px;
}

nav ul li a {
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

nav ul li a:hover {
  color: #007bff; /* Bright blue for techy effect */
}

.contact-info {
  display: flex;
  align-items: center;
  color: white;
  span {
    margin-right: 15px;
    display: flex;
    align-items: center;

    svg {
      width: 24px;
      margin-right: 6px;
    }
  }

  button {
    background: #007bff; /* Bright blue */
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
